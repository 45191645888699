import styled from 'styled-components';
import { FlexContainer } from 'containers/flexContainer';
import { TempWrapper } from '../Templates/styles'

export const ListWrapper = styled(FlexContainer)`
    margin-top: 90px;   
`
export const CardWrapper = styled(TempWrapper)`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 100%;
    gap: 10px;
    margin: auto;
    margin-top: 0px  ;
    width: 60rem;
    @media (max-width: 1200px) {
        gap: 15px;
    }
    @media (max-width: 1150px) {
        grid-template-columns: 1fr 1fr;
        width:80%;
    }
    @media(min-width: 2000px) {
        width: 72%;
        margin-bottom:4rem;
    }
    @media(min-width: 2500px) {
        margin-top:1rem;
        margin-bottom: 2rem;
    }
    @media screen and (min-width: 3000px) {
        row-gap:50px;
        column-gap: 25px;
        margin-top: 2rem;
    }
`