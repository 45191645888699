import { getAuthData } from "./authHandler";

const urlPortalData = (value = getAuthData("activeProfile"), separator) => {
  return [value, ...(value || "").split(separator)];
};
export const getPortalData = value => urlPortalData(value, "-");
export const getPortalId = value => getPortalData(value)[2];
export const getAccountId = value => getPortalData(value)[1];
export const queryOptions = (query, variables, skip = false) => [
  query,
  { ...variables },
  { skip, fetchPolicy: "store-and-network" },
];
export const clientQuery = (hook, query, variables, skip) =>
  hook(query, variables, { skip, fetchPolicy: "store-only" });

export const serverQuery = (hook, query, variables, skip = false) => {
  if (query.params.name === "userProfilesQuery" && !variables.userId)
    return { data: undefined };
  return hook(query, variables, { skip, fetchPolicy: "store-or-network" });
};

/**
 * @param {*} hook useQuery hook fromthe relay hooks
 * @param {*} query clientquery that is used to read data from store
 * @param {*} variables list of variables {} used to read the query
 * @param {*} key argument used to read the data from the query results
 * @param {*} skip should we run the query the first time or not
 * @returns the details of the selected key as a JS object
 */
export const getClientData = (hook, query, variables, key, skip = false) => {
  let { data } = clientQuery(hook, query, variables, skip);
  if (!data[`selected${key}`] || !data[`selected${key}`].details) return null;
  return JSON.parse(data[`selected${key}`]?.details);
};
