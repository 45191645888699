import { PrimaryButton } from "containers/buttons";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  overflow-y: hidden;
`;

export const Backdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  backdrop-filter: blur(36px);
  -webkit-backdrop-filter: blur(36px);
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const Modal = styled.div`
  z-index: 5;
  top: 474px;
  left: 557px;
  width: 500px;
  height: 170px;

  /* UI Properties */

  background: #2e2e2e 0% 0% no-repeat padding-box;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    margin-top: 12px;
    display: flex;
    gap: 30px;
  }
  @media screen and (min-height: 900px) {
    width: 804px;
    height: 230px;
    div {
      margin-top: 30px;
    }
    @media screen and (max-width: 1024px) {
      width: 700px;
      height: 200px;
      div {
        margin-top: 18px;
      }
    }
  }
  @media screen and (min-width: 3000px) {
    width: 1200px;
    height: 300px;
  }
  @media screen and (min-width: 4000px) {
    width: 1400px;
    height: 400px;
    div {
      margin-top: 60px;
      gap: 40px;
    }
  }
`;

export const Text = styled.p`
  text-align: center;
  font: normal normal 600 16px/30px Raleway;
  letter-spacing: 0px;
  color: #8d8d8d;
  opacity: 1;
  padding: 0 30px;
  @media screen and (min-height: 900px) {
    font: normal normal 600 25px/30px Raleway;
  }
  @media screen and (max-width: 1024px) {
    font-size: 20px;
  }
  @media screen and (min-width: 2560px) {
    font-size: 28px;
  }
  @media screen and (min-width: 3000px) {
    font-size: 40px;
    line-height: 45px;
  }
  @media screen and (min-width: 4000px) {
    font-size: 55px;
  }
`;

function DeleteModal(props) {
  const { t } = useTranslation();
  const { text, toggleModal, deleteFunc, toast } = props;
  return (
    <ModalWrapper>
      <Backdrop />
      <Modal className="bgBrightMode">
        <Text>{text}</Text>
        <div>
          <PrimaryButton
            red
            onClick={() => {
              deleteFunc();
              toggleModal(false);
              toast && toast();
            }}
          >
            {t("yes")}
          </PrimaryButton>
          <PrimaryButton gray onClick={() => toggleModal(false)}>
            {t("no")}
          </PrimaryButton>
        </div>
      </Modal>
    </ModalWrapper>
  );
}

export default DeleteModal;
