import React from 'react'
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  overflow-y: hidden;
  backdrop-filter: blur(26px);
  -webkit-backdrop-filter: blur(26px);
`;

const ModalContainer = styled.div`
  width: 30%;
  padding: 1.2em 1.2rem;
  background: #2E2E2E 0% 0% no-repeat padding-box;
  border-radius: 18px;
  p{
      font-size: 16px;
      color:#CCCCCC;
  }
`;

const Button = styled.div`
  padding: 8px 20px;
  background: #FC9621;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  width: 100px;
  display: inline-block;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  cursor: pointer;
  text-align: center;
`;


function WarnModal({handleHitTagPrompt}) {
  const { t } = useTranslation();
  return (
    <ModalWrapper>
      <ModalContainer>
        <div className="center-content">
          <p className="bigger-size">{t("Save tag")}</p>
          <Button onClick={handleHitTagPrompt}>Ok</Button>
        </div>
      </ModalContainer>
    </ModalWrapper>
  );
}

export default WarnModal;
