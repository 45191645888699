import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "./MainTemplate.scss";
import SharedText from "../SharedText/SharedText";
import { getClientData, serverQuery } from "helpers/queryHandler";
import { TemplateContext } from "context/TemplateContentContext";
import { useQuery } from "relay-hooks";
import Carousel from "../../atoms/Carousel/index";
import "@master-wizr/customizer/dist/index.css";
import clientOnly from "graphql/queries/clientOnly";
import { adaptDataFromCotextForCustomizer } from "../../../../helpers/customizer";
import profileQuery from "graphql/queries/showroom/profileQuery";
import UploadModal from "containers/Modals/UploadModal";
import {deleteFilesByUrls, uploadFilesAndDeleteByUrl } from "helpers/fileUpload";
import { STATIC_URL } from "constants/env";
import Customizer from "components/ui/organisms/Customizer";
import { getAuthData, setCustomizerData } from "helpers/authHandler";
import EditShowroomMutation from "graphql/mutations/EditShowroomMutation";
import responseHandler from "helpers/responseHandler";
import { fileUploadPrefixes } from "constants/others";
import IsLoading from "../IsLoading";
import { Modal } from "react-bootstrap";
import { createPortal } from "react-dom";
import FakeNav from "components/ui/molecules/FakeNav/FakeNav";
import useWindowDimensions from "../windowDimensions";
import { useTranslation } from "react-i18next";

function MainTemplate({
  list,
  title,
  description,
  isSubHead,
  tooltip,
  onChangeEditor,
  isPreview,
  slideNumber,
  itemsClass,
  detailsOfTemplate,
  headerImage,
  isExpandable,
  isMicrosite,
  setViewingItem,
  uploadHeader,
  imageChangesQueue,
  setImageChangesQueue,
}) {
  const [activeCustomizer, setActiveCustomizer] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  const [imageUpload, setImageUpload] = useState([]);
  const [openUploadModal, setOpenUploadModal] = useState({
    open: false,
  });
  const [activePortal, setActivePortal] = useState();
  const [isUpdating, setIsUpdating] = useState(false);
  const ref = useRef(null);
  const { context, setContext } = useContext(TemplateContext);
  const profile = getClientData(useQuery, clientOnly, {}, "Profile");
  const [rowKey, setrowKey] = useState(0);
  const portal = getClientData(useQuery, clientOnly, {}, "Portal");
  const { data } = serverQuery(useQuery, profileQuery, {
    profileId: profile?._id,
  });
  const isCreateMode = window.location.pathname.includes('showroom-template-expandable');
  const [isUploaded, setIsUploaded] = useState(false);
  const { t } = useTranslation();
  
  useEffect(() => {
    data?.profile?.accessedPortals.map((item, index) => {
      if (portal?.portalId === item?.portalInfo?._id) {
        setActivePortal(index);
      }
      return false;
    });
  }, [data, portal?.portalId]);

  const docsThumnbails = [
    {
      name: "word",
      thumnbailUrl:
        "https://cdn.cf.masterwizr.com/wordthumbnail_klkmyr.png",
    },
    {
      name: "presentation",
      thumnbailUrl:
        "https://cdn.cf.masterwizr.com/pptthumbnail_jqrrk9.png",
    },
    {
      name: "excel",
      thumnbailUrl:
        "https://cdn.cf.masterwizr.com/excelthumbnail_zxgjwn.png",
    },
    {
      name: "pdf",
      thumnbailUrl:
        "https://cdn.cf.masterwizr.com/pdfthumbnail_oycrij.png",
    },
  ];

  const handleFinishing = array => {
    var array2 = Object.keys(array).map(function (key) {
      return array[key];
    });

    const newContent = array2.map((item, index) => {
      return {
        name: `<p>Row ${index + 1}</p>`,
        content: item.map((element, i) => {
          const presId = element?._id;
          const presFiles1 = element?.files;
          const fileWithPosition1 = presFiles1?.reduce(
            (lowestPositionFile, file) => {
              const positionInfo = file?.positions?.find(
                position => position?.presentationId === presId
              );
              if (positionInfo) {
                if (
                  !lowestPositionFile ||
                  positionInfo?.position < lowestPositionFile.position
                ) {
                  return {
                    ...file,
                    position: positionInfo?.position,
                  };
                }
              }
              return lowestPositionFile;
            },
            null
          );
          return {
            image: element?.image
              ? element.image
              : `${STATIC_URL}${fileWithPosition1?.fileUrl}`,
            mainHeading: element?.mainHeading ? element.mainHeading : "",
            subHeading: element?.subHeading ? element.subHeading : "",
            presentationId: element._id,
            imageUrl: element?.imageUrl
              ? element.imageUrl
              : `${STATIC_URL}${fileWithPosition1?.fileUrl}`,
          };
        }),
      };
    });
    setContext({
      ...context,
      showroomDetails: {
        ...context.showroomDetails,
        content: newContent,
      },
    });
  };

  const finishHandle = array => {
    setActiveCustomizer(!activeCustomizer);
    handleFinishing(array);
  };

  const updateHeaderImage = async () => {
    if (!imageUpload.length) {
      return false;
    } else {
      const {uploadedResult : result } = await uploadFilesAndDeleteByUrl(imageUpload, fileUploadPrefixes.showrooms)
      if(result.length === 0) return ;
      const url = result[0].fileUrl;
      setContext({
        ...context,
        showroomDetails: {
          ...context.showroomDetails,
          headerImage: url,
        },
      });
      setIsUploaded(true);
    }
  };

  const handleOpenModal = e => {
    e.stopPropagation();
    setOpenUploadModal({
      open: true,
      data: {...list}
    });
  };

  const headerImageDeleter = async (headerImage,  modalData ) => {
    // TODO delete the current one
    await deleteFilesByUrls([headerImage])
    EditShowroomMutation(modalData?.data?._id, {headerImage: ""}, response => {
      responseHandler(response, "Showroom edited successfully.");
      setOpenUploadModal(false)
    });
  }

  const handleAdd = () => {
    const prevCookies = getAuthData("customizerState") 
    setActiveCustomizer(true);
    setCustomizerData(
      {
        state: true,
        prevTemp: prevCookies?.prevTemp,
      }
    );
  }


  useEffect(() => {
    const prevCookies = getAuthData("customizerState") || null;
    if (prevCookies?.state && activePortal !== undefined) {
      // setActiveCustomizer(prevCookies?.state);
      setInitialRender(false);
    }
  }, [activePortal]);

  useEffect(() => {
    const prevCookies = getAuthData("customizerState");
    if (!activeCustomizer && !initialRender) {
      setCustomizerData(
        {
          state: false,
          prevTemp: prevCookies?.prevTemp,
        },
        "customizerState"
      );
    }
    if (!initialRender) {
      if (prevCookies?.state !== activeCustomizer) {
        setCustomizerData({
          state: activeCustomizer,
          prevTemp: prevCookies?.prevTemp,
        });
      }
    }
  }, [activeCustomizer, initialRender]);

  useEffect(() => {
    uploadHeader && uploadHeader(updateHeaderImage);
  }, [imageUpload]);
  const flattenedContent = list?.content?.flatMap(res => res?.content) || [];
  const updatedList = {
    ...list,
    content: [{ content: flattenedContent }]
  };
  const dimensions = useWindowDimensions();
const dataList = dimensions?.width<=1024 ? updatedList : list;
  return (
    <>
      {!isPreview && (() => {
        const theNav = document.getElementById("possible-nav");
        if (theNav) {
          return createPortal(
            <FakeNav />,
            document.querySelector(".main-nav-templates")
          );
        }
        return null;
      })()}
      {isUpdating && <IsLoading isContent />}
      <div
        className={`shared-template ${itemsClass} ${
          isPreview && `previewtemplate`
        } ${itemsClass} ${isExpandable ? `expandable-shared` : ``}`}
      >
        <div className="maincontent" style={{ position: "relative" }}>
          {!isExpandable ? (
            <div className="content">
              <SharedText
                title={title}
                isSubHead={isSubHead}
                description={description}
                onChangeEditor={onChangeEditor}
                disabled={isPreview}
                noId={true}
                isShowroom={true}
              />
            </div>
          ) : (
            <div>
              <div className="content">
                <SharedText
                  title={title}
                  isSubHead={isSubHead}
                  description={description}
                  onChangeEditor={onChangeEditor}
                  disabled={isPreview}
                  noId={true}
                />
              </div>
             {dimensions?.width>1024 && (
               <div
               ref={ref}
               className={`expandable-image  expandable-${list?.templateId}`}
               style={{
                 backgroundImage: `url(${
                   !context.showroomDetails.headerImage
                     ? headerImage
                     : `${STATIC_URL}${context.showroomDetails.headerImage}`
                 })`,
               }}
             >
               <div className="icons">
                 <div
                   className="tooltip-container"
                   onClick={e => {
                     handleOpenModal(e);
                   }}
                 >
                   <button>+</button>
                   <div className="dropdown">
                     <p className="tooltiptext">{t("Add cover page")}</p>
                   </div>
                 </div>
               </div>
             </div>
              )
              }
            </div>
          )}
          {dataList?.content?.map((res, rowIndex) => {
            return (
              <div key={rowIndex}>
                <Carousel
                  showroomData={dataList}
                  index={rowIndex}
                  onChangeEditor={onChangeEditor}
                  isPreview={isPreview}
                  rowIndex={rowIndex}
                  tooltip={tooltip}
                  slideNumber={slideNumber}
                  data={res}
                  itemsLength={res.content.length}
                  templateId={dataList?.templateId}
                  detailsOfTemplate={detailsOfTemplate}
                  className={dataList?.templateId}
                  list={res.content}
                  isMicrosite={isMicrosite}
                  setViewingItem={setViewingItem}
                  imageChangesQueue={imageChangesQueue}
                  setImageChangesQueue={setImageChangesQueue}
                  handleAdd={handleAdd}
                  createShowroom={
                    window.location.pathname.split("/").pop() ===
                    list?.templateId?.toString()
                      ? true
                      : false
                  }
                  setIsUpdating={setIsUpdating}
                  setRowKey={setrowKey}
                />
              </div>
            );
          })}
        </div>
        {openUploadModal.open && (
          <UploadModal
            headerRef={ref}
            expandableHeader
            closeModal={setOpenUploadModal}
            modal={openUploadModal}
            image={imageUpload}
            setImage={setImageUpload}
            thumbnailDeleter={headerImageDeleter}
            isCreateMode={isCreateMode}
            isUploaded={isUploaded}
          />
        )}
        {activeCustomizer && (
          <Modal
            show={activeCustomizer}
            onHide={!activeCustomizer}
            animation={false}
          >
            <Customizer
              type="customizer"
              data={data?.profile?.accessedPortals?.map(
                item => item.portalInfo
              )}
              rows={detailsOfTemplate?.nrOfRows || list?.content?.length}
              setActiveCustomizer={setActiveCustomizer}
              activeCustomizer={activeCustomizer}
              finishHandle={finishHandle}
              className={list?.templateId}
              presentationNumber={1}
              array={adaptDataFromCotextForCustomizer(list.content)}
              docsThumnbails={docsThumnbails}
              activePortal={activePortal}
              activeRow={rowKey}
              templateId={list?.templateId}
            />
          </Modal>
        )}
      </div>
    </>
  );
}
export default MainTemplate;
MainTemplate.propTypes = {
  list: PropTypes.any,
  description: PropTypes.string,
  title: PropTypes.string,
  extraClass: PropTypes.string,
  tooltip: PropTypes.bool,
  icons: PropTypes.bool,
  index: PropTypes.number,
  onChangeEditor: PropTypes.func,
  imagesTemplate: PropTypes.array,
  uploaded: PropTypes.bool,
  addNewItem: PropTypes.func,
  isPreview: PropTypes.bool,
};
