import { useTour } from "@reactour/tour";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function CustomButtons({ onClickBack, visible, nextBtn, prevBtn, removeBack }) {
  const { currentStep, setCurrentStep, steps } = useTour();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleBack = () => {
    if (onClickBack) {
      navigate(-1);
      setCurrentStep(onClickBack);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleNext = () => {
    if (steps[currentStep + 1].pathname) {
      navigate(`${steps[currentStep + 1].pathname}`);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  return (
    <div className="custom-your-buttons">
      <div>
        <button
          style={{ visibility: removeBack ? "hidden" : "visible" }}
          onClick={handleBack}
        >
          {t(prevBtn || "Back")}
        </button>
      </div>
      {visible ? (
        ""
      ) : (
        <button onClick={handleNext}>{t(nextBtn || "Next")}</button>
      )}
    </div>
  );
}

export default CustomButtons;
export const steps = () => {
  return [
    {
      selector: ".click-card-0",
      index: 0,
      content: (
        <div>
          <Steps
            title="define-show-room"
            description="Combine-2-20-presentation"
          />
          <CustomButtons />
        </div>
      ),
      stepInteraction: false,
    },
    {
      selector: ".click-card-1",
      index: 1,
      content: (
        <div>
          <Steps
            title="define-cover-page"
            description="customize-show-room-with-cover-page"
          />
          <br />
          <CustomButtons />
        </div>
      ),
      stepInteraction: false,
    },
    {
      index: 2,
      selector: ".click-card-2",
      content: (
        <div>
          <Steps
            title="define-micro-site"
            description="you-can-create-a-micro-site"
          />
          <br />
          <CustomButtons />
        </div>
      ),
      stepInteraction: false,
    },
    {
      index: 3,
      selector: ".guideCards",
      content: (
        <div>
          <Steps description="click-one-of-the-options-that-stood-out" />
          <br />
          <CustomButtons visible={true} nextBtn="explore-more" />
        </div>
      ),
    },
    {
      index: 4,
      selector: ".main-wrapper-templates",
      content: (
        <div>
          <Steps description="choose-one-of-the-templates-to-start" />
          <br />
          <CustomButtons visible={true} />
        </div>
      ),
      position: [-5, 3],
      pathname: "/showroom-templates",
    },
    {
      index: 5,
      selector: ".studiocustomizer",
      content: (
        <div>
          <Steps description="select-templates-you-want-to-have" />
          <br />
          <CustomButtons visible={true} />
        </div>
      ),
      position: [-5, 3],
    },
    {
      index: 6,
      selector: ".main-shared-text",
      content: (
        <div>
          <Steps description="add-title-and-subheading" />
          <br />
          <CustomButtons nextBtn={"Explore more"} />
        </div>
      ),
      pathname: "/showrooms/1",
    },
    {
      index: 7,
      selector: ".showroom-bg-toggle",
      content: (
        <div>
          <Steps description="click-to-show-the-background-image" />
          <br />
          <CustomButtons visible={true} />
        </div>
      ),
    },
    {
      index: 8,
      selector: ".modals-backgrounds",
      content: (
        <div>
          <Steps description="choose-one-of-the-background-images" />
          <br />
          <CustomButtons visible={true} />
        </div>
      ),
    },
    {
      index: 9,
      selector: ".previewbtn",
      content: (
        <div>
          <Steps description="click-button-to-preview-your-creation" />
          <br />
          <CustomButtons visible={true} />
        </div>
      ),
    },
    {
      index: 10,
      selector: ".eye-preview",
      content: (
        <div>
          <Steps description="click-button-to-go-back-to-the-editing-mode" />
          <br />
          <CustomButtons visible={true} />
        </div>
      ),
    },
    {
      index: 11,
      selector: ".separate-button",
      content: (
        <div>
          <Steps description="click-button-to-finish-creating-your-showroom" />
          <br />
          <CustomButtons visible={true} />
        </div>
      ),
    },

    //coverpages
    {
      index: 12,
      selector: ".main-wrapper-coverpages",
      content: (
        <div>
          <Steps description="choose-one-of-the-cover-templates-to-start-with" />
          <br />
          <CustomButtons visible={true} />
        </div>
      ),
      pathname: "/coverpage-templates",
    },
    {
      index: 13,
      selector: ".templateWrapper",
      content: (
        <div>
          <Steps description="replace-the-placeholder-text-with-your-text" />
          <br />
          <CustomButtons />
        </div>
      ),
      pathname: "/coverpage-detailed",
    },
    {
      index: 14,
      selector: ".plus-icon",
      content: (
        <div>
          <Steps description="change-background-image-of-the-selected-cover" />
          <br />
          <CustomButtons visible={true} />
        </div>
      ),
    },
    {
      index: 15,
      selector: ".modal-wrapper",
      content: (
        <div>
          <Steps description="choose-file-click-done" />
          <br />
          <CustomButtons visible={true} removeBack={true} />
        </div>
      ),
    },
    {
      index: 16,
      selector: ".separate-button",
      content: (
        <div>
          <Steps description="click-button-to-finish-creating-your-cover-page" />
          <br />
          <CustomButtons visible={true} />
        </div>
      ),
    },
    {
      index: 17,
      selector: ".micrositeBtn",
      content: (
        <div>
          <Steps description="click-button-to-finish-creating-micro-site" />
          <br />
          <CustomButtons visible={true} />
        </div>
      ),
      pathname: "/showrooms",
    },
    {
      index: 18,
      selector: ".framethemes",
      content: (
        <div>
          <Steps description="choose-one-of-the-options-below-and-continue" />
          <br />
          <CustomButtons visible={true} />
        </div>
      ),
    },
    {
      index: 19,
      selector: ".list-content-wrapper",
      content: (
        <div>
          <Steps description="select-the-showrooms-you-want-to-have-in-the-micro-site" />
          <br />
          <CustomButtons />
        </div>
      ),
    },
    {
      index: 20,
      selector: ".bottom-btn",
      content: (
        <div>
          <Steps description="select-coverpage-if-you-want-any" />
          <br />
          <CustomButtons visible={true} />
        </div>
      ),
    },
    {
      index: 21,
      selector: ".list-content-wrapper",
      content: (
        <div>
          <Steps description="select-coverpage-you-want-in-a-micro-site" />
          <br />
          <CustomButtons />
        </div>
      ),
      pathname: "/cover-pages/",
    },
    {
      index: 22,
      selector: ".finish-select",
      content: (
        <div>
          <Steps description="click-button-to-finish-creating-your-micro-site" />
          <br />
          <CustomButtons visible={true} />
        </div>
      ),
    },
    {
      index: 23,
      selector: ".finishmicrosite",
      content: (
        <div>
          <Steps description="Click Save to save your microsite" />
          <br />
          <CustomButtons visible={true} />
        </div>
      ),
    },
  ];
};

const Steps = ({ title, description }) => {
  const { t } = useTranslation();
  return (
    <>
      {title ? <h1>{t(title)}</h1> : <></>}
      {t(description)}
    </>
  );
};
