import graphql from "babel-plugin-relay/macro";

const userProfiles = graphql`
  query userProfilesQuery($userId: ID!) {
    user(id: $userId) {
      ... on User {
        _id
        email
        location
        lastName
        telephone
        firstName
        otherNames
        signupMethods
        twoFactorAuth {
          enabled
          method
        }
        profiles {
          _id
          locale
          accountId
          accountRole
          profilePhoto
          defaultPortal
          accessedModules {
            name
            role
            tier
          }
          isDefaultAccount
          accountInfo {
            _id
            name
            theme
            logoUrl
            isActive
            logoShape
            backgroundImage
            font
          }
          portalGroups {
            _id
            name
            isDefault
            position
            portals {
              portalId
              portalInfo {
                _id
                name
              }
            }
          }
          accessedPortals {
            portalId
            portalRole
            portalInfo {
              _id
              name
              accountId
              libraryViews
            }
          }
        }
      }
    }
  }
`;

export default userProfiles;
