import { Component } from "react";
import DesignEditor from "../../atoms/DesignEditor/DesignEditor";
import DetailedPage from "../../atoms/CoverPages/DetailedPage";
import { image, overlay } from "../DesignLeft";
import StickyNav from "containers/NavBar";
import DeleteModal from "containers/Modals/deleteModal";
import SavingModal from "containers/Modals/SavingModal";
import CoverPage2 from "../../../../containers/Templates/coverpages/cover2";
import CoverPage3 from "../../../../containers/Templates/coverpages/cover3";
import CoverPage4 from "../../../../containers/Templates/coverpages/cover4";
import CoverPage5 from "../../../../containers/Templates/coverpages/cover5";
import CoverPage6 from "../../../../containers/Templates/coverpages/cover6";
import CreateCoverpageMutation from "graphql/mutations/CreateCoverpageMutation";
import EditCoverpageMutation from "graphql/mutations/EditCoverpageMutation";
import DeleteCoverpageMutation from "graphql/mutations/DeleteCoverpageMutation";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showErrorToast, showSuccessToast } from "@master-wizr/toast";
import { ExitButton } from "containers/Templates/styles";
import { withRouter } from "../../../../routes/withRouter";
import { getAccountId } from "helpers/queryHandler";
import responseHandler from "helpers/responseHandler";
import i18next from "i18next";
class CoverPagePreivew extends Component {
  state = {
    coverpage: {
      name: "Coverpage Test 2.1",
      mainHeading: `<h2>Title</h2>`,
      details: `<p>Aenean malesuada, lacus volutpat mollis molestie, lorem massa molestie risus, non consectetur mi mauris iaculis mauris. Pellentesque ut sapien id dolor cursus rhoncus id ac odio. Nam sollicitudin felis sem, in ultrices nisi imperdiet at. Cras sem ante, rutrum a velit non, semper convallis neque. In luctus aliquam purus.</p>`,
      showroomLink: i18next.t("Checkout Showrooms"),
      accountId: getAccountId(),
      templateId: 2,
      creator: "61f7d0bd60dfeb58a9808f86",
      showroomIds: [],
    },
    backgroundUrl:
      "https://master-wizr-uploads.s3.amazonaws.com/studio/images.png",
    textColor: "#FFFFFF",
    showDeleteModal: false,
    showSaveModal: false,
    edited: true,
    isPreview: true,
    isCheckoutShowrooms: false,
  };

  componentDidMount() {
    const urlTempId = Number(window.location.pathname.split("/")[2]);
    this.setState({
      coverpage: { ...this.state.coverpage, templateId: urlTempId },
    });
  }

  onChangeEditor = editorValue => content => {
    switch (editorValue) {
      case "name":
        if (this.state.coverpage.name !== content.target.value) {
          let coverpage = {
            ...this.state.coverpage,
            name: content.target.value,
          };
          this.setState({ coverpage });
        }
        break;
      case "mainHeading":
        if (this.state.coverpage.mainHeading !== content) {
          let coverpage = { ...this.state.coverpage, mainHeading: content };
          this.setState({ coverpage });
        }
        break;
      case "subHeading":
        if (this.state.coverpage.details !== content) {
          let coverpage = { ...this.state.coverpage, details: content };
          this.setState({ coverpage });
        }
        break;

      default:
        break;
    }
  };

  changeBackgroudUrl = (backgroundUrl, textColor) => {
    this.setState({ backgroundUrl, textColor });
  };

  toggleDeleteModal = toggle => {
    this.setState({ showDeleteModal: toggle });
  };

  updateCoverPageState = coverpage => {
    const urlTempId = Number(window.location.pathname.split("/")[2]);
    this.setState({
      coverpage: {
        ...this.state.coverpage,
        ...coverpage,
        templateId: urlTempId,
      },
    });
  };

  navigateBack = () => {
    if (this.state.edited) {
      this.props.history.push("/");
    } else {
      this.props.history.push("/");
    }
  };

  handleEditCoverpage = () => {
    const {
      _id: editCoverpageId,
      id,
      __typename,
      updatedAt,
      templateId,
      createdAt,
      creator,
      ...edited
    } = this.state.coverpage;
    EditCoverpageMutation(editCoverpageId, edited, response => {
      responseHandler(response, "Coverpage edited successfully");
    });
    this.setState({ edited: false });
  };

  handleSaveCoverpage = () => {
    CreateCoverpageMutation(this.state.coverpage, response => {
      responseHandler(response, "Coverpage created successfully", () => {
        let { coverpage } = response;
        this.setState({ coverpage });
        this.props.navigate("/cover-pages");
      });
    });
    this.setState({ edited: false });
  };

  handleSave = () => {
    // Implentation to add login for saving content.
    this.navigateBack();
  };

  deleteCoverpage = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const { id, showroomid } = params;
    if (id) {
      DeleteCoverpageMutation(id, response => {
        responseHandler(response, "Coverpage deleted successfully", () =>{
          this.props.navigate("/cover-pages");

        });
        this.toggleDeleteModal();
      });
    }
    // TODO Disable delete if Id is present.
  };
  renderSwitch = ({ isPreview }) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const { id, showroomid } = params;
    const templateId = Number(window.location.pathname.split("/")[2]);
    const mapper = {
      1: { Component: DetailedPage },
      2: { Component: CoverPage3 },
      3: { Component: CoverPage5 },
      4: { Component: CoverPage2 },
      5: { Component: CoverPage4 },
      6: { Component: CoverPage6 },
      7: { Component: CoverPage2 },
      8: { Component: CoverPage3 },
    };
    const {
      state: { coverpage, backgroundUrl, textColor },
      onChangeEditor,
      updateCoverPageState,
    } = this;
    const current = mapper[templateId];
    return current ? (
      <>
        <current.Component
          coverpageData={coverpage}
          showroomid={showroomid}
          onChangeEditor={onChangeEditor}
          backgroundUrl={backgroundUrl}
          textColor={textColor}
          templateId={templateId}
          updateCoverpage={updateCoverPageState}
          isPreview={true}
          disabled={true}
          handleCheckoutShowrooms={this.handleCheckoutShowrooms}
        />
      </>
    ) : (
      <></>
    );
  };

  onClickPreview = () => {
    this.setState({ ...this.state, isPreview: true });
    const editorList = document.querySelectorAll(".tox-editor-container");

    editorList.forEach((editor, _) => {
      if (!editor.classList.contains("editor-hide")) {
        editor.style.display = "none";
      }
    });
  };

  closePreview = () => {
    this.setState({ ...this.state, isPreview: false });
    const editorList = document.querySelectorAll(".tox-editor-container");

    editorList.forEach((editor, _) => {
      if (!editor.classList.contains("editor-hide")) {
        editor.style.display = "none";
      }
    });
  };

  handleCheckoutShowrooms = () => {
    this.setState({ ...this.state, isCheckoutShowrooms: true });
  };

  closeCheckoutShowrooms = () => {
    this.setState({ ...this.state, isCheckoutShowrooms: false });
  };

  render() {
    const {
      state: {
        coverpage,
        backgroundUrl,
        textColor,
        showDeleteModal,
        showSaveModal,
      },
      onChangeEditor,
      changeBackgroudUrl,
      toggleDeleteModal,
      navigateBack,
      handleSave,
      handleEditCoverpage,
      handleSaveCoverpage,
      deleteCoverpage,
    } = this;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const { id, showroomid } = params;
    var coverpageid = this.props.coverPageId;
    if (!this.props.isMicroSite) {
      coverpageid = id;
    }
    return (
      <div className="designtool-template">
        <StickyNav
          clickEvent={() => this.props.setShowCoverPage(false)}
          isMicrosite
          noitems
          handleText={"Close"}
        />
        <DesignEditor
          title={"Cover Page"}
          image={image}
          overlay={overlay}
          coverpageId={coverpageid}
          changeBackgroudUrl={changeBackgroudUrl}
          handleSaveCoverpage={handleSaveCoverpage}
          onChangeMetaData={() => {}}
          isPreview={true}
          isCheckoutShowrooms={this.state.isCheckoutShowrooms}
          closeCheckoutShowrooms={this.closeCheckoutShowrooms}
        >
          {this.renderSwitch({
            isPreview: true,
            handleCheckoutShowrooms: this.isCheckoutShowrooms,
          })}
        </DesignEditor>
        {showDeleteModal && (
          <DeleteModal
            text={"Are you sure you want to delete this coverpage?"}
            toggleModal={toggleDeleteModal}
            deleteFunc={deleteCoverpage}
          />
        )}
        {showSaveModal && <SavingModal handleSave={handleSave} />}
      </div>
    );
  }
}
export default withRouter(CoverPagePreivew);
