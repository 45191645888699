import React from "react";
import styles from "./BackBtn.module.scss";
import { ReactComponent as Back } from "assets/images/backicon.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function BackBtn({ onClick, isPreview, isMicrosite, magazineClick, extraClass }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  
  const canGoBack = window.history.length > 1  && window?.history?.state?.key;
  return canGoBack ? (
    <div
      onClick={() =>
        onClick ? onClick() : magazineClick ? magazineClick() : navigate(-1)
      }
      className={`${styles.btn} ${
        (isPreview || isMicrosite) && styles.previewbtn
      } back-btn ${styles[extraClass]}`}
    >
      <Back /> 
      <span>{t("Back")}</span>
    </div>
  ) : null;
}

export default BackBtn;
