/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LOCALE = "BN" | "DE" | "EN" | "ES" | "FR" | "NO" | "PT" | "SQ" | "%future added value";
export type ROLES = "ADMINISTRATOR" | "CREATOR" | "EDITOR" | "PUBLISHER" | "STUDENT" | "SUPERADMINISTRATOR" | "TRAINER" | "VIEWER" | "%future added value";
export type SHAPE = "DEFAULT" | "ROUNDED" | "%future added value";
export type SIGNUP_METHODS = "EMAIL" | "FACEBOOK" | "GOOGLE" | "MICROSOFT" | "TWITTER" | "%future added value";
export type THEME = "BLUE" | "BRIGHT" | "DARK" | "GREEN" | "PURPLE" | "%future added value";
export type TIER = "LITE" | "REGULAR" | "%future added value";
export type VERIFICATION_METHOD = "EMAIL" | "TELEPHONE" | "%future added value";
export type userProfilesQueryVariables = {|
  userId: string
|};
export type userProfilesQueryResponse = {|
  +user: ?{|
    +_id?: string,
    +email?: string,
    +location?: ?string,
    +lastName?: ?string,
    +telephone?: ?string,
    +firstName?: ?string,
    +otherNames?: ?string,
    +signupMethods?: ?$ReadOnlyArray<?SIGNUP_METHODS>,
    +twoFactorAuth?: ?{|
      +enabled: boolean,
      +method: VERIFICATION_METHOD,
    |},
    +profiles?: $ReadOnlyArray<?{|
      +_id: string,
      +locale: ?LOCALE,
      +accountId: string,
      +accountRole: ROLES,
      +profilePhoto: ?string,
      +defaultPortal: ?string,
      +accessedModules: ?$ReadOnlyArray<?{|
        +name: string,
        +role: ?ROLES,
        +tier: ?TIER,
      |}>,
      +isDefaultAccount: ?boolean,
      +accountInfo: ?{|
        +_id: string,
        +name: string,
        +theme: ?THEME,
        +logoUrl: ?string,
        +isActive: ?boolean,
        +logoShape: ?SHAPE,
        +backgroundImage: ?string,
        +font: ?string,
      |},
      +portalGroups: ?$ReadOnlyArray<?{|
        +_id: string,
        +name: string,
        +isDefault: ?boolean,
        +position: number,
        +portals: ?$ReadOnlyArray<?{|
          +portalId: string,
          +portalInfo: ?{|
            +_id: string,
            +name: string,
          |},
        |}>,
      |}>,
      +accessedPortals: ?$ReadOnlyArray<?{|
        +portalId: string,
        +portalRole: ?ROLES,
        +portalInfo: ?{|
          +_id: string,
          +name: string,
          +accountId: string,
          +libraryViews: ?$ReadOnlyArray<?string>,
        |},
      |}>,
    |}>,
  |}
|};
export type userProfilesQuery = {|
  variables: userProfilesQueryVariables,
  response: userProfilesQueryResponse,
|};
*/


/*
query userProfilesQuery(
  $userId: ID!
) {
  user(id: $userId) {
    __typename
    ... on User {
      _id
      email
      location
      lastName
      telephone
      firstName
      otherNames
      signupMethods
      twoFactorAuth {
        enabled
        method
      }
      profiles {
        _id
        locale
        accountId
        accountRole
        profilePhoto
        defaultPortal
        accessedModules {
          name
          role
          tier
        }
        isDefaultAccount
        accountInfo {
          _id
          name
          theme
          logoUrl
          isActive
          logoShape
          backgroundImage
          font
        }
        portalGroups {
          _id
          name
          isDefault
          position
          portals {
            portalId
            portalInfo {
              _id
              name
            }
          }
        }
        accessedPortals {
          portalId
          portalRole
          portalInfo {
            _id
            name
            accountId
            libraryViews
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "userId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "portalId",
  "storageKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "telephone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "otherNames",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "signupMethods",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TwoFactorAuth",
      "kind": "LinkedField",
      "name": "twoFactorAuth",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "enabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "method",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Profile",
      "kind": "LinkedField",
      "name": "profiles",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "locale",
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountRole",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profilePhoto",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "defaultPortal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ModuleRole",
          "kind": "LinkedField",
          "name": "accessedModules",
          "plural": true,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "role",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tier",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isDefaultAccount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Account",
          "kind": "LinkedField",
          "name": "accountInfo",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "theme",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "logoUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isActive",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "logoShape",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "backgroundImage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "font",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PortalGroups",
          "kind": "LinkedField",
          "name": "portalGroups",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isDefault",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "position",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PortalInGroup",
              "kind": "LinkedField",
              "name": "portals",
              "plural": true,
              "selections": [
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Portal",
                  "kind": "LinkedField",
                  "name": "portalInfo",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    (v4/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PortalRoles",
          "kind": "LinkedField",
          "name": "accessedPortals",
          "plural": true,
          "selections": [
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "portalRole",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Portal",
              "kind": "LinkedField",
              "name": "portalInfo",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                (v4/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "libraryViews",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "userProfilesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "userProfilesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e8ff2cc5f1caf7696683249e649b3d6c",
    "id": null,
    "metadata": {},
    "name": "userProfilesQuery",
    "operationKind": "query",
    "text": "query userProfilesQuery(\n  $userId: ID!\n) {\n  user(id: $userId) {\n    __typename\n    ... on User {\n      _id\n      email\n      location\n      lastName\n      telephone\n      firstName\n      otherNames\n      signupMethods\n      twoFactorAuth {\n        enabled\n        method\n      }\n      profiles {\n        _id\n        locale\n        accountId\n        accountRole\n        profilePhoto\n        defaultPortal\n        accessedModules {\n          name\n          role\n          tier\n        }\n        isDefaultAccount\n        accountInfo {\n          _id\n          name\n          theme\n          logoUrl\n          isActive\n          logoShape\n          backgroundImage\n          font\n        }\n        portalGroups {\n          _id\n          name\n          isDefault\n          position\n          portals {\n            portalId\n            portalInfo {\n              _id\n              name\n            }\n          }\n        }\n        accessedPortals {\n          portalId\n          portalRole\n          portalInfo {\n            _id\n            name\n            accountId\n            libraryViews\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fb6881c6ba78368ccca1e11e78948ffd';

module.exports = node;
